<template>
  <div class="plant-record">
    <x-table
      :no-data-text="CA('plantRecord_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="140">
        <FormItem label="所属地块" prop="area">
          <Cascader
            :data="farmScene"
            v-model="form.area"
            style="width: 300px"
          ></Cascader>
        </FormItem>
        <FormItem label="种植品种" prop="categoryId">
          <!-- <Cascader :data="category" v-model="form.categoryId"></Cascader> -->
          <Select v-model="form.categoryId" style="width: 300px">
            <Option v-for="item in category" :value="item.id" :key="item.id">{{
              item.name
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="种植方式" prop="plantType">
          <Select v-model="form.plantType" style="width: 300px">
            <Option v-for="item in plantType" :value="item.id" :key="item.id">{{
              item.name
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="预计结束时间" prop="endTime">
          <DatePicker
            type="date"
            v-model="form.endTime"
            style="width: 300px"
          ></DatePicker>
        </FormItem>
        <!-- <FormItem label="预估总产量" prop="output">
          <Input v-model="form.output" style="width: 300px">
            <span slot="append">KG</span>
          </Input>
        </FormItem> -->
        <!-- <FormItem label="单位产量养分需求" prop="standardValue">
          <Input v-model="form.standardValue" style="width: 300px">
            <span slot="append">KG</span>
          </Input>
        </FormItem> -->
        <FormItem label="粪肥供给养分占比" prop="fertilizationSupplyPercentage">
          <!-- <Input
            v-model="form.fertilizationSupplyPercentage"
            style="width: 300px"
          >
            <span slot="append">%</span>
          </Input> -->
          <Select
            v-model="form.fertilizationSupplyPercentage"
            style="width: 300px"
          >
            <Option
              v-for="item in areaLevel"
              :value="item.manureFertilizationPercentage"
              :key="item.id"
              >{{ item.levelName }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="粪肥占施肥比例" prop="manureFertilizationPercentage">
          <Input
            v-model="form.manureFertilizationPercentage"
            style="width: 300px"
          >
            <span slot="append">%</span>
          </Input>
        </FormItem>
        <FormItem label="作物元素" prop="elementId">
          <Select v-model="form.elementId" style="width: 300px">
            <Option v-for="item in element" :value="item.id" :key="item.id">{{
              item.elementName
            }}</Option>
          </Select>
        </FormItem>
        <!-- <FormItem label="粪肥当季利用率" prop="seasonUtilization">
          <Input v-model="form.seasonUtilization" style="width: 300px">
            <span slot="append">%</span>
          </Input>
        </FormItem> -->
        <!-- <FormItem label="单位猪当量粪肥养分供给量" prop="nutrientSupply">
          <Input v-model="form.nutrientSupply" style="width: 300px">
            <span slot="append">KG</span>
          </Input>
        </FormItem> -->
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.loading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
import moment from "moment";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "所属基地",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.farmName}</span>;
            },
          },
          {
            title: "所属地块",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.sceneName}</span>;
            },
          },
          {
            title: "种植品种",
            width: 100,
            align: "center",
            key: "categoryName",
          },
          {
            title: "种植方式",
            width: 100,
            key: "plantName",
          },
          // {
          //   title: "预估总产量",
          //   width: 110,
          //   align: "center",
          //   render: (h, { row }) => {
          //     return <span>{row.output} KG</span>;
          //   },
          // },
          // {
          //   title: "单位产量养分需求",
          //   width: 150,
          //   align: "center",
          //   render: (h, { row }) => {
          //     return <span>{row.standardValue} KG</span>;
          //   },
          // },
          {
            title: "粪肥供给养分占比",
            width: 150,
            align: "center",
            render: (h, { row }) => {
              return <span>{row.fertilizationSupplyPercentage * 100} %</span>;
            },
          },
          {
            title: "粪肥占施肥比例",
            width: 140,
            align: "center",
            render: (h, { row }) => {
              return <span>{row.manureFertilizationPercentage * 100} %</span>;
            },
          },
          {
            title: "作物元素",
            width: 100,
            key: "elementName",
          },
          // {
          //   title: "粪肥当季利用率",
          //   width: 140,
          //   align: "center",
          //   render: (h, { row }) => {
          //     return <span>{row.seasonUtilization * 100} %</span>;
          //   },
          // },
          // {
          //   title: "单位猪当量粪肥养分供给量",
          //   width: 210,
          //   align: "center",
          //   render: (h, { row }) => {
          //     return <span>{row.nutrientSupply} KG</span>;
          //   },
          // },
          // {
          //   title: "种植时间",
          //   minWidth: 180,
          //   key: "createTime",
          // },
          // {
          //   title: "结束时间",
          //   minWidth: 180,
          //   key: "endTime",
          // },
          {
            title: "状态",
            width: 100,
            key: "statusName",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {row.status == 1 && this.CA("plantRecord_stop") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定结束吗?"
                      on-on-ok={() => this.stop(row.id)}
                    >
                      <a style="margin-right: 10px">结束</a>
                    </Poptip>
                  )}
                  {row.status == 1 && this.CA("plantRecord_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {row.status == 1 && this.CA("plantRecord_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                  {row.status == 2 && <span>--</span>}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        area: [],
        farmId: "",
        sceneId: "",
        categoryId: "",
        siteType: "1",
        // output: "",
        // standardValue: "",
        fertilizationSupplyPercentage: "",
        manureFertilizationPercentage: "",
        // seasonUtilization: "",
        // nutrientSupply: "",
        plantType: "",
        endTime: "",
        elementId: "",
      },
      rules: {
        area: [{ required: true, type: "array", message: "请选择地块" }],
        categoryId: [{ required: true, message: "请选择品种" }],
        plantType: [{ required: true, message: "请选择种植方式" }],
        endTime: [{ required: true, message: "请填写预计结束时间" }],
        // output: [
        //   { required: true, message: "请填写预估总产量" },
        //   {
        //     validator(rule, value, callback) {
        //       if (!CU.validateNumber(value)) {
        //         callback(rule.message);
        //         return;
        //       }
        //       callback();
        //     },
        //     message: "请填写正确的预估总产量",
        //   },
        // ],
        // standardValue: [
        //   { required: true, message: "请填写单位产量养分需求" },
        //   {
        //     validator(rule, value, callback) {
        //       if (!CU.validateNumber(value)) {
        //         callback(rule.message);
        //         return;
        //       }
        //       callback();
        //     },
        //     message: "请填写正确的单位产量养分需求",
        //   },
        // ],
        // fertilizationSupplyPercentage: [
        //   { required: true, message: "请选择粪肥供给养分占比" },
        // ],
        // manureFertilizationPercentage: [
        //   { required: true, message: "请填写粪肥占施肥比例" },
        //   {
        //     validator(rule, value, callback) {
        //       if (!CU.validateNumber(value, 0, 100)) {
        //         callback(rule.message);
        //         return;
        //       }
        //       callback();
        //     },
        //     message: "请填写正确的粪肥占施肥比例",
        //   },
        // ],
        // elementId: [{ required: true, message: "请选择作物元素" }],
        // seasonUtilization: [
        //   { required: true, message: "请填写粪肥当季利用率" },
        //   {
        //     validator(rule, value, callback) {
        //       if (!CU.validateNumber(value, 0, 100)) {
        //         callback(rule.message);
        //         return;
        //       }
        //       callback();
        //     },
        //     message: "请填写正确的粪肥当季利用率",
        //   },
        // ],
        // nutrientSupply: [
        //   { required: true, message: "请填写单位猪当量粪肥养分供给量" },
        //   {
        //     validator(rule, value, callback) {
        //       if (!CU.validateNumber(value)) {
        //         callback(rule.message);
        //         return;
        //       }
        //       callback();
        //     },
        //     message: "请填写正确的单位猪当量粪肥养分供给量",
        //   },
        // ],
      },
      category: [],
      farmScene: [],
      plantType: [],
      areaLevel: [],
      element: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增种植记录",
            ca: "plantRecord_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "所属地块",
              component: "cascader",
              data: this.farmScene,
              defaultValue: [],
              field: "area",
            },
            {
              conditionName: "种植品种",
              component: "select",
              data: this.category,
              defaultValue: "",
              field: "categoryId",
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "种植方式",
              component: "select",
              data: this.plantType,
              defaultValue: "",
              field: "plantType",
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "作物元素",
              component: "select",
              data: this.element,
              defaultValue: "",
              field: "elementId",
              parameterField: "id",
              showField: "elementName",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增种植记录",
        submitLoading: false,
      };
    },
    search(data) {
      let obj = {
        farmId: data.area[0],
        sceneId: data.area[1],
        categoryId: data.categoryId,
        ...data,
      };
      this.search_data = obj;
      this.page.pageNo = 1;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    getList() {
      if (!this.CA("plantRecord_check")) return;
      this.table.loading = true;
      this.$post(this.$api.LIVESTOCK_ON_HAND.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "1",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getCategory() {
      this.$post(this.$api.USER_PRODUCT.LIST, {
        type: 1,
      }).then((res) => {
        this.category = res.list;
      });
    },
    getPlantType() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "209",
      }).then((res) => {
        this.plantType = res.list;
      });
    },
    getFarmScene() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE, {
        siteType: "1",
      }).then((res) => {
        console.log(res)
        this.farmScene = CU.formatTreeData(res, "id", "name", "sceneList");
      });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.area = [row.farmId, row.sceneId];
      this.form.plantType = row.plantType;
      this.form.categoryId = row.categoryId;
      // this.form.fertilizationSupplyPercentage =
      //   row.fertilizationSupplyPercentage;
      this.form.manureFertilizationPercentage =
        row.manureFertilizationPercentage * 100;
      // this.form.seasonUtilization = row.seasonUtilization * 100;
      this.form.plantType = row.plantType.toString();
      this.modal = {
        show: true,
        title: "编辑种植记录",
        submitLoading: false,
      };
    },
    stop(id) {
      this.$post(this.$api.LIVESTOCK_ON_HAND.STOP, {
        id,
      }).then(() => {
        this.$Message.success("已结束");
        this.getList();
      });
    },
    del(id) {
      this.$post(this.$api.LIVESTOCK_ON_HAND.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        area: [],
        farmId: "",
        sceneId: "",
        categoryId: "",
        siteType: "1",
        // output: "",
        // standardValue: "",
        fertilizationSupplyPercentage: "",
        manureFertilizationPercentage: "",
        // seasonUtilization: "",
        // nutrientSupply: "",
        plantType: "",
        endTime: "",
        elementId: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.form.area[0];
        params.sceneId = this.form.area[1];
        params.manureFertilizationPercentage =
          this.form.manureFertilizationPercentage / 100;
        // params.seasonUtilization = this.form.seasonUtilization / 100;
        params.endTime = moment(this.form.endTime).format("YYYY-MM-DD");
        delete params.area;
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.LIVESTOCK_ON_HAND.EDIT
            : this.$api.LIVESTOCK_ON_HAND.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    getAreaLevel() {
      this.$post(this.$api.AREA_LEVEL.LIST).then((res) => {
        this.areaLevel = res.list;
      });
    },
    getElement() {
      this.$post(this.$api.ELEMENT.LIST).then((res) => {
        this.element = res.list;
      });
    },
  },
  mounted() {
    this.getList();
    this.getCategory();
    this.getFarmScene();
    this.getPlantType();
    this.getAreaLevel();
    this.getElement();
  },
};
</script>

<style lang="less" scoped>
.plant-record {
  width: 100%;
  height: 100%;
}
</style>